import React, {useContext, useEffect, useRef, useState} from 'react';
import {GetRef, InputNumber, InputRef} from 'antd';
import {Button, Form, Input, Popconfirm, Table} from 'antd';
import post from "../Request";

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface User {
    id: number;
    name: string | undefined;
    balance: number;
    inviteCode: string;
    free: number;
    drawed: number;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof User;
    record: User;
    handleSave: (record: User) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                       title,
                                                       editable,
                                                       children,
                                                       dataIndex,
                                                       record,
                                                       handleSave,
                                                       ...restProps
                                                   }) => {
    const [editing, setEditing] = useState(false);
    const form = useContext(EditableContext)!;


    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({[dataIndex]: record[dataIndex]});
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{margin: 0}}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <InputNumber onPressEnter={save} onBlur={save} min={0} step={1000}/>
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{paddingRight: 24}} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];


type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const User: React.FC = () => {
    const [dataSource, setDataSource] = useState<User[]>([]);
    const [total, setTotal] = useState(0)
    const [size, setSize] = useState(20)
    const [page, setPage] = useState(1)

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'name',
            dataIndex: 'name',
        },
        {
            title: 'balance',
            dataIndex: 'balance',
            editable: true,
        },
        {
            title: 'inviteCode',
            dataIndex: 'inviteCode',
        },
        {
            title: 'free',
            dataIndex: 'free',
        },
        {
            title: 'drawed',
            dataIndex: 'drawed',
        },
    ];

    const loadData = (page: number, size: number) => {
        setSize(size)
        post('/sys/user/list', {
            params: {
                page: page - 1,
                size: size
            },
            success: (data: any) => {
                setDataSource(data.data)
                setTotal(data.total)
                setPage(page)
            }
        })
    }
    const updateUser=(user:User)=>{
        post('/sys/user/update', {
            params: user,
            success: (data: any) => {
                loadData(page, size)
            }
        })
    }

    useEffect(() => {
        loadData(1, 20)
    }, []);


    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: User) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: updateUser,
            }),
        };
    });

    return (
        <div>
            <Table
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={dataSource}
                columns={columns as ColumnTypes}
                pagination={{
                    defaultCurrent: 1,
                    defaultPageSize: size,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    total: Math.ceil(total/size),
                    showTotal: () => `共 ${total} 条记录`,
                    onChange: loadData,
                }}
            />
        </div>
    );
};

export default User;