import {
    Button, Card,
    Checkbox,
    Col,
    Flex,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    List,
    Modal,
    notification,
    Row,
    Spin,
    Switch,
    Tabs,
    TabsProps, Tooltip, Typography,
    Upload
} from "antd";
import {Content} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import React, {JSX, useEffect, useState} from "react";
import post from "../Request";
import TextArea from "antd/es/input/TextArea";
import Config from "../Config";
import Meta from "antd/es/card/Meta";
import * as vm from "node:vm";
import cookie from "react-cookies";
// import InfiniteScroll from 'react-infinite-scroll-component';
// var InfiniteScroll = require('react-infinite-scroll-component');


function Poster(props: {
    width: number,
    height: number,
    value?: string | undefined,
    id?: string,
    url?: string,
    onChange?: (url: string) => void,
    onUpload?: (url: string) => void
}) {
    return <Flex gap="middle" align="center">
        <Image src={Config.fileUrl(props.value)} width={props.width} height={props.height}/>
        <Upload listType={'picture'} accept={'image/png,image/gif,image/webp'}
                action=''
                customRequest={(opt) => {
                    const data = new FormData()
                    data.append('file', opt.file)
                    fetch('/sys/upload', {
                        method: 'POST',
                        body: data
                    })
                        .then((res) => res.json())
                        .then((res) => {
                            if (res.code === 200) {
                                // setNewUrl(res.data)
                                // onUpload(res.data)
                                if (props.onChange) props.onChange(res.data)
                            } else {
                                notification.error({
                                    message: '上传失败',
                                    description: res.msg
                                })
                            }
                        })
                        .catch((e) => {
                            notification.error({
                                message: '上传失败',
                                description: e.message
                            })
                        })
                    return false
                }}
                showUploadList={false}
                progress={{
                    strokeColor: {
                        '0%': '#108ee9',
                        '100%': '#87d068',
                    },
                    strokeWidth: 3,
                    format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
                }}
        >
            <Button icon={<UploadOutlined/>}>上传</Button>
        </Upload>
    </Flex>
}


interface BoxItem {
    "id": number,
    "name": string,
    "price": number,
    "poster": string | undefined,
    "icon": string | undefined,
    "iconChecked": string | undefined,
    "isDef": boolean,
    "state": number,
    "remark": string | undefined,
}

interface Wares {
    id: number,
    name: string,
    logo: string,
    price: number,
    level: string,
    level_id: number,
    remark: string | undefined,
    state: number,
}

interface Level {
    "id": number,
    "name": string,
    "value": number
}


const WareList = ({open, dismiss, box}: {
    open: boolean,
    dismiss: (ref: boolean) => void,
    box?: BoxItem
}) => {
    const [selected, setSelected] = useState<number[]>([])
    const [wares, setWares] = useState<Wares[]>([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(10)

    const loadData = (page, size) => {
        post('/sys/box/wares_out_box', {
            params: {page: page - 1, size: size, box_id: box?.id},
            success: (data) => {
                setWares(data.wares)
                setTotal(data.total)
            }
        })

    }

    const submit = () => {
        post('/sys/box/add_wares', {
            params: {
                box_id: box?.id,
                w_id: selected
            },
            success: (data) => {
                dismiss(true)
            }
        })
    }
    useEffect(() => {
        loadData(page , size)
    }, [box])
    return <>
        <Modal open={open}
               onOk={submit}
               onCancel={() => dismiss(false)}
               okButtonProps={{disabled: selected == undefined}}
        >
            <List dataSource={wares}
                  renderItem={(item) => (
                      <List.Item onClick={() => {
                          const index = selected.findIndex((id) => id == item.id);
                          if (index == -1) {
                              setSelected([...selected, item.id])
                          } else {
                              setSelected(selected.filter((id) => id != item.id))
                          }
                      }}>
                          <Checkbox
                              checked={selected.find((id) => item.id == id) != undefined}
                              style={{marginRight: 16}}/>

                          <List.Item.Meta
                              avatar={<Image width={80} height={80} src={Config.fileUrl(item.logo)}/>}
                              title={item.name}
                              description={<div>
                                  <span>价格: {item.price}<br/>等级: {item.level}</span>
                                  <Tooltip title={item.remark} placement={"topLeft"}>
                                      <div style={{
                                          overflow: 'clip',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap'
                                      }}>{item.remark}</div>
                                  </Tooltip>
                              </div>}/>
                      </List.Item>
                  )}
                  pagination={{
                      align: 'center',
                      total: total,
                      defaultCurrent: page,
                      defaultPageSize: size,
                      showQuickJumper: true,
                      showTotal: () => '共 ' + total + ' 条',
                      onChange: (page, size) => {
                          loadData(page, size)
                      }
                  }}>
            </List>
        </Modal>
    </>
}


const Detail = ({box, refresh}: { box: BoxItem | undefined, refresh: () => void }) => {

    const [form] = Form.useForm()
    useEffect(() => {
        form.setFieldsValue({
            id: '',
            name: '',
            price: '',
            remark: '',
            isDef: '',
            poster: '',
            enable: true,
            ...box
        })
    }, [box]);

    const save = () => {
        let values = form.getFieldsValue();
        values.id = box?.id;
        values.state = values.enable ? 0 : 1;
        const url = box?.id != 0 ? '/sys/box/update' : '/sys/box/insert'
        post(url, {
            params: values,
            success: (_) => {
                refresh()
            }
        })
    }

    return <>
        <Form form={form} component={false}>
            <Row gutter={[24, 8]} align={'middle'}>
                <Col span={2}>
                    <span>海报:</span>
                </Col>
                <Col flex={'auto'}>
                    <Form.Item name={'poster'}>
                        <Poster width={280} height={180}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 8]} align={'middle'}>
                <Col span={2}>
                    <span>图标:</span>
                </Col>
                <Col flex={'auto'}>
                    <Form.Item name={'icon'} rules={[{required: true, message: '不能为空'}]}>
                        <Poster width={100} height={80} url={box?.icon}
                                onUpload={(url) => {
                                    form.setFieldsValue({'icon': url})
                                }}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 8]} align={'middle'}>
                <Col span={2}>
                    <span>选中效果:</span>
                </Col>
                <Col flex={'auto'}>
                    <Form.Item name={'iconChecked'} rules={[{required: true, message: '不能为空'}]}>
                        <Poster width={100} height={80} url={box?.iconChecked}
                                onUpload={(url) => {
                                    form.setFieldsValue({'iconChecked': url})
                                }}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 8]} align={'middle'}>
                <Col span={2}>
                    <span>名称:</span>
                </Col>
                <Col flex={'auto'}>
                    <Form.Item name={'name'} rules={[{required: true, message: '不能为空'}]}>
                        <Input placeholder={'请输入名称'} style={{width: 400}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 8]} align={'middle'}>
                <Col span={2}>
                    <span>价格:</span>
                </Col>
                <Col flex={'auto'}>
                    <Form.Item name={'price'}
                               rules={[{required: true, message: '不能为空'}, {min: 100, message: '>100'}]}>
                        <InputNumber placeholder={'请输入价格'} style={{width: 400}} step={100} min={100}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 8]} align={'middle'}>
                <Col span={2}>
                    <span>备注:</span>
                </Col>
                <Col flex={'auto'}>
                    <Form.Item name={'remark'}>
                        <TextArea placeholder={'请输入'} rows={4} maxLength={255}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[24, 8]} align={'middle'}>
                <Col span={2}>
                    <span>默认:</span>
                </Col>
                <Col flex={'auto'}>
                    <Form.Item name={'isDef'}>
                        <Switch/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 8]} align={'middle'}>
                <Col span={2}>
                    <span>启用:</span>
                </Col>
                <Col flex={'auto'}>
                    <Form.Item name={'enable'}>
                        <Switch defaultChecked={box?.state == 0}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4} offset={4}>
                    <Button type={'primary'} onClick={save}>保存</Button>
                </Col>
                <Col span={4}>
                    <Button onClick={() => {
                    }}>重置</Button>
                </Col>
            </Row>
        </Form>
    </>
}

const InputLevel = ({value, onChange}: { value?: any, onChange?: (value: any) => void }) => {
    return <InputNumber addonBefore={value.name} min={0} max={100} value={value.value} onChange={(v) => {
        if (onChange) onChange({value: v, name: value.name})
        return v
    }}/>
}

const Wares = ({box, refresh}: { box: BoxItem | undefined, refresh: () => void }) => {

    const [level, setLevel] = useState<Level[]>()
    const [wares, setWares] = useState<Wares[]>()
    const [open, setOpen] = useState(false)

    const [checked, setChecked] = useState<number[]>([])


    const [form] = Form.useForm()

    const saveWeight = () => {
        const values = form.getFieldsValue()
        const params = values.weight?.map((item: any) => {
            return {lv_id: item.lv_id, value: item.value.value}
        });
        post('/sys/box/updateWeight', {
            params: {box_id: box?.id, weight: params},
            success: (data) => {
                refresh()
            }
        })
    }

    const delWare = () => {
        console.log(checked)
        post('/sys/box/del_wares', {
            params: {box_id: box?.id, wares: checked},
            success: (data) => {
                refresh()
            }
        })
    }

    const reset = () => {
    }

    useEffect(() => {
        const lv = level?.map((item) => {
            return {lv_id: item.id, value: {value: item.value, name: item.name}}
        })
        form.setFieldsValue({weight: lv})
    }, [level, box]);
    useEffect(() => {
        setChecked([])
        post('/sys/box/wares', {
            params: {id: box?.id},
            success: (data) => {
                setLevel(data.levels)
                setWares(data.wares)
            }
        })
    }, [box]);

    const children = wares?.map((item, index) => {
        return <Card extra={<Checkbox checked={checked.includes(item.id)}
                                      onChange={(e) => {
                                          if (e.target.checked) {
                                              setChecked([...checked, item.id])
                                          } else {
                                              setChecked(checked.filter(i => i != item.id))
                                          }
                                      }}/>}
                     title={item.name}
                     onClick={() => {
                         if (checked.includes(item.id)) {
                             setChecked([...checked, item.id])
                         } else {
                             setChecked(checked.filter(i => i != item.id))
                         }
                     }}>
            <Meta avatar={<Image width={80} height={80} src={Config.fileUrl(item.logo)}/>}
                  style={{width: '200px'}}
                  description={<div>
                      <span>价格: {item.price}</span><br/>
                      <span>等级: {item.level}</span>
                      <Tooltip title={item.remark} placement={"topLeft"}>
                          <div style={{
                              overflow: 'clip',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                          }}>{item.remark}</div>
                      </Tooltip>
                  </div>}/>
        </Card>
    })
    return <>
        <Row align={'middle'} wrap={false} style={{marginTop: 16}}>
            <Col span={2}>概率:</Col>
            <Col flex={'auto'}>
                <Form form={form}>
                    <Flex gap={8} wrap={'wrap'}>
                        <Form.List name={'weight'}>
                            {(fields) => (
                                <>
                                    {fields.map((field) => (
                                        <>
                                            <Form.Item noStyle name={[field.name, 'lv_id']}>
                                                <Input style={{display: 'none'}}/>
                                            </Form.Item>
                                            <Form.Item noStyle name={[field.name, 'value']}>
                                                <InputLevel/>
                                            </Form.Item>
                                        </>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Flex>
                </Form>
            </Col>
        </Row>
        <Row align={'middle'} wrap={false} style={{marginTop: 16}}>
            <Col span={2}>奖品:</Col>
            <Col flex={'auto'} key={'btn'}>
                <Flex gap={'large'} align={'center'} wrap={'wrap'}>
                    {children}
                    <Button type={'dashed'} style={{width: 80, height: 80}}
                            onClick={() => {
                                setOpen(!open)
                            }}><PlusOutlined/></Button>
                </Flex>
            </Col>
        </Row>
        <Row align={'middle'} style={{marginTop: 16}}>
            <Col span={4} offset={4}>
                <Button type={'primary'} onClick={saveWeight}>保存</Button>
            </Col>
            <Col>
                <Button onClick={reset}>重置</Button>
            </Col>
            {checked.length > 0 && (
                <Col offset={2}>
                    <Button onClick={delWare}>删除</Button>
                </Col>
            )}
        </Row>
        {
            open && <WareList open={open && box != undefined}
                              box={box}
                              dismiss={(ref) => {
                                  setOpen(false)
                                  if (ref) refresh()
                              }}/>
        }
    </>
}


const Box = () => {

    const [boxes, setBoxes] = useState<BoxItem[]>([]);
    const [loading, setLoading] = useState(false)

    const [selected, setSelected] = useState<BoxItem | undefined>()


    const refresh = () => {
        setLoading(true)
        setSelected(undefined)
        post('/sys/box/list', {
            success: (data: BoxItem[]) => {
                setBoxes(data)
                const def = data.find((item) => item.id == selected?.id);
                setSelected(def ?? data[0])
            },
            finally: () => {
                setLoading(false)
            }
        })
    }


    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: `详情`,
            children: <Detail box={selected} refresh={refresh}/>,
        },
        {
            key: '2',
            label: `物品`,
            children: <Wares box={selected} refresh={refresh}/>,
        }
    ]

    useEffect(() => {
        refresh()
    }, []);


    const [tabKey, setTabKey] = useState('1')

    return (
        <Layout style={{height: '100%', width: '100%'}}>
            <Sider trigger={null} width={256}>
                <List dataSource={boxes} renderItem={(item, index) => (
                    <List.Item key={item.id.toString()} onClick={() => {
                        setSelected(item)
                    }} style={{backgroundColor: item === selected ? '#e8f5fe' : 'transparent', paddingLeft: 16}}>
                        <List.Item.Meta
                            avatar={<Image src={Config.fileUrl(item.icon)} width={60} height={60}/>}
                            title={item.name}
                            description={item.price}
                        />
                    </List.Item>
                )}
                      style={{width: '100%', height: '100%', overflow: 'auto', backgroundColor: 'white'}}>
                    <div style={{margin: "auto", width: '100%', padding: 8}}>
                        <Button icon={<PlusOutlined/>} style={{width: '100%',}} onClick={() => {
                            let box: BoxItem = {
                                icon: undefined, iconChecked: undefined, poster: undefined,
                                id: 0,
                                name: '新箱子',
                                price: 100,
                                isDef: false,
                                remark: '',
                                state: 0,
                            }
                            setBoxes([...boxes, box])
                            setSelected(box)
                        }}>添加</Button>
                    </div>
                </List>
            </Sider>
            <Content style={{marginLeft: 20, overflowY: 'auto', overflowX: "hidden"}}>
                {!loading && (
                    <Tabs items={tabs} style={{height: '100%', width: '100%'}} activeKey={tabKey}
                          onChange={(k) => setTabKey(k)}/>
                )}
                {loading && (
                    <Spin tip="Loading..." size={"large"} style={{margin: 'auto',}}>
                        <div className='content'/>
                    </Spin>
                )}
            </Content>
        </Layout>
    )
}
export default Box;