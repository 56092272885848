import {useEffect, useState} from "react";
import post from "../Request";

interface Level {
    id: number,
    name: string,
}

export const Level = () => {
    const [levels, setLevels] = useState<Level[]>()
    useEffect(() => {
        post('/sys/levels', {
            success: (data: Level[]) => {
                setLevels(data)
            },
        })
    }, []);
    return <>
        Level
        {
            levels?.map((lv) => {
                return <p>{lv.name}</p>
            })
        }
    </>
}