import React, {useState} from 'react';
import {
    AndroidOutlined,
    DropboxOutlined,
    HomeOutlined,
    MailOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ProductOutlined,
    UserOutlined,
    DollarOutlined,
    OrderedListOutlined,
    AccountBookOutlined
} from '@ant-design/icons';
import {Button, Flex, Image, Layout, Menu, Select, theme} from 'antd';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import icon from '../assets/icon.webp'

const {Header, Sider, Content} = Layout;

const LV = () => {
    return <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
        <title>LV</title>
        <path
            d="M704.443733 102.4a102.4 102.4 0 0 1 77.1072 35.0208l114.756267 131.2768A102.4 102.4 0 0 1 921.6 336.0768v170.018133a102.4 102.4 0 0 1-27.170133 69.461334l-307.2 332.8a102.4 102.4 0 0 1-150.459734 0l-307.2-332.8A102.4 102.4 0 0 1 102.4 506.094933v-169.984a102.4 102.4 0 0 1 25.2928-67.413333l114.756267-131.2768A102.4 102.4 0 0 1 319.5904 102.4h384.853333z m0 68.266667H319.556267a34.133333 34.133333 0 0 0-22.7328 8.704l-2.9696 2.9696-114.756267 131.2768a34.133333 34.133333 0 0 0-8.192 18.363733L170.666667 336.0768v170.018133a34.133333 34.133333 0 0 0 6.3488 19.831467l2.730666 3.310933 307.2 332.8a34.133333 34.133333 0 0 0 47.035734 2.9696l3.072-2.9696 307.2-332.8a34.133333 34.133333 0 0 0 8.8064-18.875733l0.273066-4.266667v-169.984a34.133333 34.133333 0 0 0-5.905066-19.2512l-2.525867-3.242666-114.756267-131.2768a34.133333 34.133333 0 0 0-21.504-11.400534L704.4096 170.666667zM682.666667 273.066667a34.133333 34.133333 0 0 1 0 68.266666H341.333333a34.133333 34.133333 0 1 1 0-68.266666h341.333334z"/>
    </svg>
}
const menuItems = [
    {
        key: '/main',
        title: 'System',
        icon: <HomeOutlined/>,
        label: <Link to="">System</Link>,
    },
    {
        key: '/main/box',
        icon: <DropboxOutlined/>,
        label: <Link to="box">Box</Link>,
    },
    {
        key: '/main/wares',
        icon: <ProductOutlined/>,
        label: <Link to="wares">产品</Link>,
    },
    {
        key: '/main/level',
        icon: <LV/>,
        label: <Link to="level">&nbsp;&nbsp;等级</Link>,
    },
    {
        key: '/main/user',
        icon: <UserOutlined/>,
        label: <Link to="user">用户</Link>,
    },
    {
        key: '/main/order',
        icon: <OrderedListOutlined/>,
        label: <Link to="order">订单</Link>,
    },
    {
        key: '/main/bill',
        icon: <DollarOutlined/>,
        label: <Link to="bill">账单</Link>,
    },
    {
        key: '/main/recharge',
        icon: <AccountBookOutlined/>,
        label: <Link to="recharge">充值</Link>,
    },
    {
        "key": '/main/feedback',
        icon: <MailOutlined/>,
        label: <Link to="feedback/list">反馈</Link>,
    },
    {
        key: '/main/xlsx',
        label: <Link to={'xlsx'}>xlsx</Link>
    },
    {
        "key": '/main/app',
        icon: <AndroidOutlined/>,
        label: "APP",
        children: [
            {
                key: '/main/app/latest',
                label: <Link to="app/latest">最新</Link>,
            },
            {
                key: '/main/app/history',
                label: <Link to="app/history">历史</Link>,
            }
        ]
    }
];


const FeedbackFiller = () => {
    let location = useLocation()
    if (location.pathname != "/main/feedback/list") return <></>
    const options = [
        {value: 'ALL', label: '全部'},
        {value: 'WAIT', label: '待处理'},
        {value: 'DELAY', label: '延后'},
        {value: 'DONE', label: '已处理'},
    ]
    const [status, setStatus] = useState('ALL')
    const navigate = useNavigate()
    return (
        <div>
            <span style={{margin: '16px', fontWeight: 'bold'}}>状态:</span>
            <Select value={status} options={options} style={{width: '120px'}}
                    onChange={(value) => {
                        setStatus(value)
                        navigate(`/main/feedback/list`, {state: {status: value}});
                    }}/>
        </div>
    )
}

const MainLayout: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();


    let location = useLocation();

    const [key, setKey] = useState([location.pathname]);

    return (
        <Layout style={{height: '100vh', width: '100vw'}}>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <a onClick={() => setKey(['1'])}>
                    <Link to={''}>
                        <Flex gap={8} align={"center"} justify={"center"} style={{marginTop: 16}} wrap={'wrap'}>
                            <Image src={icon} preview={false} width={40} height={40}/>
                            <span style={{color: 'white', fontSize: 20}}>Home</span>
                        </Flex>
                    </Link>
                </a>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={key}
                    onSelect={(k) => {
                        setKey(k.keyPath)
                    }}
                    selectedKeys={key}
                    items={menuItems}
                />
            </Sider>
            <Layout>
                <Header style={{padding: 0, background: colorBgContainer}}>
                    <Flex>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                width: 64,
                                height: 64,
                            }}
                        />
                        <FeedbackFiller/>
                    </Flex>
                </Header>
                <Content
                    style={{
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                        paddingLeft: 16,
                        paddingRight: 16,
                        overflow: 'auto',
                    }}
                >
                    <Outlet/>
                </Content>
            </Layout>
        </Layout>
    );
};

export default MainLayout;

