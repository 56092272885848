import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';
import MainLayout from './components/Main';
import System from './components/System';
import Box from './components/Box';
import Wares from "./components/Wares";
import React from "react";
import User from "./components/User";
import Login from "./components/Login";
import Order from "./components/Order";
import AppLatest from "./components/app/Latest";
import AppHistory from "./components/app/AppHistory";
import Feedback from "./components/feedback/Feedback";
import {Detail} from "./components/feedback/Detail";
import {Bill} from "./components/Bill";
import {Recharge} from "./components/Recharge";
import {Excel} from "./components/Excel";
import {Level} from "./components/Level";


const router = createBrowserRouter(
    createRoutesFromElements(<>
            <Route path="/" element={<Login/>}/>
            <Route path="/main" element={<MainLayout/>}>
                <Route path="" element={<System/>} index/>
                <Route path='box' element={<Box/>}/>
                <Route path='wares' element={<Wares/>}/>
                <Route path='level' element={<Level/>}/>
                <Route path='user' element={<User/>}/>
                <Route path='order' element={<Order/>}/>
                <Route path='feedback/list' element={<Feedback/>}/>
                <Route path='feedback/detail' element={<Detail/>}/>
                <Route path='bill' element={<Bill/>}/>
                <Route path='recharge' element={<Recharge/>}/>
                <Route path='app/latest' element={<AppLatest/>}/>
                <Route path='app/history' element={<AppHistory/>}/>
                <Route path={'xlsx'} element={<Excel/>}/>
                <Route path="*" element={<span>404</span>}/>
            </Route>
        </>
    )
);


export default () => {
    return (
        <RouterProvider router={router}/>
    )
}