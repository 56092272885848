class Config {
    private static HTTP_PREFIX = import.meta.env.VITE_BASE_URL

    private static HTTP_FILE_PREFIX: string = "/file/"


    static fileUrl = (fileName: string | undefined) => {
        if (fileName?.startsWith("http")) {
            return fileName;
        }
        if (fileName?.startsWith("/")) {
            return Config.HTTP_FILE_PREFIX + fileName.substring(1);
        }
        return Config.HTTP_FILE_PREFIX + fileName;
    }

    static getUrl = (path: string) => {
        if (path.startsWith("http")) {
            return path;
        }
        if (!path.startsWith("/")) {
            return "/" + path.substring(1);
        }
        return path;
    }


}

export default Config;