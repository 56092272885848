import React, {useEffect, useState} from 'react';
import {InboxOutlined} from '@ant-design/icons';
import {Button, Descriptions, Dropdown, Image, Input, notification, QRCode, Upload, UploadProps} from "antd";
import {RcFile} from "antd/lib/upload";
import Config from "../../Config";
import AppInfoParser from 'app-info-parser/dist/app-info-parser'
import icon from '../../assets/icon.webp'
import SparkMD5 from 'spark-md5'

interface AppInfo {
    icon: string | undefined,
    name: string | undefined,
    package: string | undefined,
    versionCode: number | undefined,
    versionName: string | undefined,
    minSdkVersion: number | undefined,
    targetSdkVersion: number | undefined,
    buildVersionName: string | undefined,
    buildVersionCode: number | undefined,
    permissions: string[] | undefined,
    description: string | undefined,
    size: number | undefined,
    updateDate: number | undefined,
}

interface AppItem {
    href: string,
    info: AppInfo
}


function AppLatest(prop: { info?: AppItem | undefined }) {
    // const AppInfoParser = require('app-info-parser');
    const [latest, setLatest] = useState<AppInfo>({} as AppInfo)
    const [appInfo, setAppInfo] = useState<AppInfo>({} as AppInfo)
    const [file, setFile] = useState<RcFile>()
    const [loading, setLoading] = useState(false)
    const [href, setHref] = useState("")

    const loadData = () => {
        if (prop.info) {
            setAppInfo(prop.info!.info)
            setHref(Config.getUrl(prop.info!.href))
            return
        }
        fetch('/sys/app/latest')
            .then(response => response.json())
            .then(data => {
                if (data.code == 200) {
                    if (data.data == null) {
                        setLatest({} as AppInfo)
                        setAppInfo({} as AppInfo)
                        setHref("")
                        return
                    }
                    setLatest(data.data.info)
                    setAppInfo(data.data.info)
                    setHref(Config.getUrl(data.data.href))
                } else {
                    notification.warning({message: 'error', description: data.msg})
                }
            }).catch((error) => {
            notification.error({message: 'error', description: error.message})
        })
    }
    useEffect(() => {
        loadData()
    }, []);

    const getUploadFileMD5 = (file) => {
        return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();


            fileReader.onload = () => {
                const arrayBuffer = fileReader.result;
                const md5Hash = SparkMD5.ArrayBuffer.hash(arrayBuffer);
                resolve(md5Hash);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };

            fileReader.readAsArrayBuffer(file);
        });
    }

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        showUploadList: false,
        action: '/api/upload',
        beforeUpload(file) {
            const parser = new AppInfoParser(file);
            parser.parse()
                .then((result: any) => {

                    getUploadFileMD5(file)
                        .then((md5: string) => {
                            setAppInfo({
                                icon: result.icon,
                                name: result.application.label[0],
                                package: result.package,
                                versionCode: result.versionCode,
                                versionName: result.versionName,
                                minSdkVersion: result.usesSdk.minSdkVersion,
                                targetSdkVersion: result.usesSdk.targetSdkVersion,
                                buildVersionName: result.platformBuildVersionName,
                                buildVersionCode: result.platformBuildVersionCode,
                                permissions: result.usesPermissions.map((item: any) => item.name),
                                description: '',
                                size: file.size,
                                updateDate: 0,
                            })
                            setFile(file)
                        })
                        .catch((e) => {
                            notification.warning({message: '解析失败', description: e.message})
                            setFile(undefined)
                        });

                })
                .catch((e: any) => {
                    notification.warning({message: '解析失败', description: e.message})
                    setFile(undefined)
                })
            return false
        },
        accept: 'application/vnd.android.package-archive'
    };

    const save = () => {
        if (!file || !appInfo) {
            notification.warning({message: '请选择文件'})
            return
        }
        setLoading(true)
        let form = new FormData()
        form.append('file', file)
        form.append('info', JSON.stringify(appInfo, null, 4))
        console.log(JSON.stringify(appInfo, null, 4))
        fetch('/sys/app/update', {
            method: 'POST',
            body: form,
        })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false)
                loadData()
                setFile(undefined)
                setAppInfo({} as AppInfo)
                notification.success({message: '保存成功', description: data.msg})
            })
            .catch((e) => {
                notification.error({message: '保存失败', description: e.message})
                setLoading(false)
                console.log(e)
            })
    }


    return (
        <div>
            <Descriptions bordered column={2} title={<span>Name: {appInfo?.name}</span>} extra={<>
                {href.length > 0 && <Dropdown.Button menu={{
                    items: [{
                        label: <QRCode value={href} icon={icon}/>, key: '1',
                    }]
                }} onClick={() => {
                    // console.log(window.location.host)
                    console.log(href)
                    // alert(window.location.host)
                    // window.location.href = window.location.host+href
                    let link = document.createElement("a");
                    link.target="_blank";
                    link.href = href;
                    link.click();
                }}>
                    Download</Dropdown.Button>
                }
            </>}>
                <Descriptions.Item label={'Icon'} children={<Image src={appInfo?.icon} width={60} height={60}/>}/>
                <Descriptions.Item label={'Package'} children={appInfo?.package}/>
                <Descriptions.Item label={'VersionCode'} children={appInfo?.versionCode}/>
                <Descriptions.Item label={'VersionName'} children={appInfo?.versionName}/>
                <Descriptions.Item label={'MinSdkVersion'} children={appInfo?.minSdkVersion}/>
                <Descriptions.Item label={'TargetSdkVersion'} children={appInfo?.targetSdkVersion}/>
                <Descriptions.Item label={'BuildVersionCode'} children={appInfo?.buildVersionCode}/>
                <Descriptions.Item label={'BuildVersionName'} children={appInfo?.buildVersionName}/>
                <Descriptions.Item label={'Update Date'} children={appInfo?.updateDate}/>
                <Descriptions.Item label={'Size'} children={((appInfo?.size ?? 0) / 1024.0 / 1024).toFixed(2) + 'M'}/>
                {/*<Descriptions.Item label={'MD5'} children={appInfo?.md5} span={2}/>*/}
                <Descriptions.Item label={'Permissions'} children={<>
                    {
                        appInfo?.permissions?.map((item: any) => <span style={{display: 'block'}}>{item}</span>)
                    }
                </>} span={2}/>
                {!file && <Descriptions.Item label={'Description'} children={appInfo?.description} span={2}/>}
                {file && <Descriptions.Item label={'Description'} span={2}
                                            children={<Input.TextArea rows={4} maxLength={200} showCount allowClear
                                                                      disabled={loading}
                                                                      onChange={(e) => {
                                                                          setAppInfo({
                                                                              ...appInfo,
                                                                              description: e.target.value
                                                                          })
                                                                      }}/>}/>}
            </Descriptions>
            <div style={{display: !prop.info ? 'block' : 'none'}}>
                <div style={{textAlign: 'right', padding: 16}}>
                    {file && <><Button style={{marginRight: 16}} onClick={() => {
                        setAppInfo(latest)
                        setFile(undefined)
                    }} disabled={loading}>取消</Button>
                        <Button type={"primary"} onClick={save} disabled={loading}>保存</Button></>}
                </div>
                <Upload.Dragger {...props} disabled={loading}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{color: 'lightgrey'}}/>
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                        banned files.
                    </p>
                </Upload.Dragger>
            </div>
        </div>
    );
}

export default AppLatest;
