import {notification} from "antd";
import Config from "./Config";


interface PostInfo {
    params?: any,
    success?: (data: any) => void,
    failed?: (error: any) => void
    finally?: () => void
}


const post = (url: string, info?: PostInfo) => {


    const openNotificationWithIcon = (msg: string) => {

        notification.error({
            message: 'Request Error',
            description: msg,
            onClick: () => {
                //  console.log('Notification Clicked!');
            },
        })
    };


    fetch(url, {
        method: "post",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(info?.params)
    })
        .then((resp) => resp.json())
        .then(data => {
            if (info?.success) {
                if (data.code === 200) info?.success(data.data)
                else if (data.code === 401) {
                    window.location.href = '/'
                } else {
                    if (info?.failed) info?.failed(data.msg)
                    openNotificationWithIcon(data.msg)
                }
            }
        })
        .catch(error => {
            openNotificationWithIcon(error.message)
        })
        .finally(() => {
            if (info?.finally) info?.finally()
        })
}


export default post;