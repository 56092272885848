import {Button, Image, Modal, Table} from "antd";
import {useEffect, useState} from "react";
import post from "../../Request";
import AppLatest from "./Latest";

interface AppInfo {
    icon: string | undefined,
    name: string | undefined,
    package: string | undefined,
    versionCode: number | undefined,
    versionName: string | undefined,
    minSdkVersion: number | undefined,
    targetSdkVersion: number | undefined,
    buildVersionName: string | undefined,
    buildVersionCode: number | undefined,
    permissions: string[] | undefined,
    description: string | undefined,
    size: number | undefined,
    updateDate: number
}

interface AppItem {
    href: string,
    info: AppInfo
}

const AppHistory = () => {
    const [dataSource, setDataSource] = useState<AppInfo[]>()
    const [list, setList] = useState<AppItem[]>([])

    const [modal, holder] = Modal.useModal()
    const showInfo = (info: AppInfo) => {
        const item = list.find((item) => {
            return item.info.updateDate == info.updateDate
        })
        if (!item) return
        modal.success({
            title:'详情',
            icon: <></>,
            content: <AppLatest info={item}/>,
            width: 'auto',
            closable: true
        })
    }


    const columns = [
        {title: 'App', dataIndex: 'name', key: 'name'},
        {
            title: 'Icon',
            dataIndex: 'icon',
            key: 'icon',
            render: (_: any, record: AppInfo) => <Image src={record.icon} width={60} height={60}/>
        },
        {title: '版本号', dataIndex: 'versionCode', key: 'versionCode'},
        {title: '版本名', dataIndex: 'versionName', key: 'versionName'},
        {title: '更新内容', dataIndex: 'description', key: 'description'},
        {
            title: '更新时间', dataIndex: 'updateTime', key: 'updateDate', render: (_: any, record: AppInfo) => {
                return new Date(record.updateDate).toLocaleString()
            }
        },
        {
            title: '查看', dataIndex: '-', key: '-', render: (_: any, record: AppInfo) => {
                return <Button type={'link'} onClick={() => showInfo(record)}>查看</Button>
            }
        }
    ];

    useEffect(() => {
        post("/sys/app/list", {
            success: (data: AppItem[]) => {
                setList(data)
                setDataSource(data.map((item: AppItem) => item.info))
            }
        })
    }, []);
    return <div>
        <Table columns={columns} dataSource={dataSource} pagination={false}></Table>
        {holder}
    </div>
}

export default AppHistory