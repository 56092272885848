import {LockOutlined, UserOutlined,} from '@ant-design/icons';
import {LoginFormPage, ProFormText,} from '@ant-design/pro-components';
import {Image, theme} from 'antd';
import React from "react";
import post from "../Request";
import {useNavigate} from "react-router-dom";
import cookie from 'react-cookies'
import icon from '../assets/icon.webp'


const Login = () => {
    const {token} = theme.useToken();
    const navigate = useNavigate()


    const submit = (params: any) => {
        post('/sys/login', {
            params: params,
            success: (data: any) => {
                cookie.save('account',params.username,{maxAge: 60 * 60 * 2})
                cookie.save('password',params.password,{maxAge: 60 * 60 * 2})
                navigate('/main')
            },
        })
    }
    return (
        <div style={{
            backgroundColor: 'white',
            height: '100vh',
        }}>
            <LoginFormPage
                backgroundImageUrl="https://mdn.alipayobjects.com/huamei_gcee1x/afts/img/A*y0ZTS6WLwvgAAAAAAAAAAAAADml6AQ/fmt.webp"
                logo={<Image src={icon} preview={false} style={{borderRadius: 8}} /> }
                backgroundVideoUrl="https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr"
                title={"Easy Box"}
                containerStyle={{
                    backgroundColor: 'rgba(255, 255, 255,0.4)',
                    backdropFilter: 'blur(4px)',
                }}
                subTitle="后台管理系统"
                onFinish={submit}
            >

                <ProFormText
                    name="username"
                    fieldProps={{
                        size: 'large',
                        prefix: (
                            <UserOutlined
                                style={{
                                    color: token.colorText,
                                }}
                                className={'prefixIcon'}
                            />
                        ),
                    }}
                    placeholder={'用户名: admin or user'}
                    rules={[
                        {
                            required: true,
                            message: '请输入用户名!',
                        },
                    ]}
                />
                <ProFormText.Password
                    name="password"
                    fieldProps={{
                        size: 'large',
                        prefix: (
                            <LockOutlined
                                style={{
                                    color: token.colorText,
                                }}
                                className={'prefixIcon'}
                            />
                        ),
                    }}
                    placeholder={'密码: ant.design'}
                    rules={[
                        {
                            required: true,
                            message: '请输入密码！',
                        },
                    ]}
                />
                <div
                    style={{
                        marginBlockEnd: 24,
                    }}
                >
                    <a style={{
                        float: 'right',
                    }}
                    >
                        忘记密码
                    </a>
                </div>
            </LoginFormPage>
        </div>
    );
};


export default Login;