import ReactDOM from 'react-dom/client';
import '/index.css';
import App from './App';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/locale/zh_CN'
import React from "react";
import {ProConfigProvider} from "@ant-design/pro-components";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <ProConfigProvider >
        <ConfigProvider wave={{disabled: true}} locale={zhCN}>
            <App/>
        </ConfigProvider>
    </ProConfigProvider>
);
document.title='Easy Box'