import * as XLSX from 'xlsx'
import {Button, Upload, UploadProps} from "antd";
import {UploadOutlined} from "@ant-design/icons";


export const Excel = () => {

    const props: UploadProps = {
        name: 'file',
        maxCount: 1,
        beforeUpload: (file) => {
            console.log(file)
            const reader = new FileReader()
            reader.readAsArrayBuffer(file)
            reader.onload = (e) => {
                const workbook = XLSX.read(e?.target?.result, {type: 'binary'})
                console.log(workbook)
               const json= XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
                console.log(JSON.stringify(json))
            }
            return false
        }
    }
    return <>

        <Upload {...props}>
            <Button icon={<UploadOutlined/>}>Select File</Button>
        </Upload>
        <Button onClick={() => {
        }}>Excel</Button>
    </>
}