import {
    Button,
    Col,
    DatePicker, Drawer,
    Flex,
    Form,
    Image,
    Input,
    InputRef,
    message,
    Modal,
    Row,
    Select,
    Space,
    Table
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import post from "../Request";
import Config from "../Config";
import {useForm} from "antd/lib/form/Form";


interface Filter {
    page: number,
    size: number,
    name: string | undefined,
    phone: string | undefined,
    status: number[] | undefined,
    start: string | undefined,
    end: string | undefined,
    sn: string | undefined,
    snt: number | undefined,
    sort: number | undefined,
}

interface DataType {
    id: number,
    sn: string,
    orderSn: number | undefined,
    name: string,
    phone: string,
    address: string,
    ware: string,
    logo: string,
    level: number,
    price: number,
    // 物流状态 1: 备货, 2: 发货, 3: 运输, 4: 送达: 7: 已收货
    status: number,
    createTime: string,
}

const Detail = (props: { data: DataType | undefined }) => {
    interface Detail {
        status: number,
        createTime: string,
    }

    const [detail, setDetail] = useState<Detail[]>()
    useEffect(() => {
        post("/sys/order/logistics/" + props.data?.id, {
            params: {},
            success: (data: Detail[]) => {
                setDetail(data)
            }
        })
    }, [props.data,props.data?.status])
    const getStatus = (status: number) => {
        // 物流状态 0:付运费 1: 备货, 2: 发货, 3: 运输, 4: 送达: 7: 已收货
        switch (status) {
            case 0:
                return '待付款'
            case 1:
                return '备货中'
            case 2:
                return '已发货'
            case 3:
                return '运输中'
            case 4:
                return '已送达'
            case 7:
                return '已收货'
            default:
                return '未知'
        }
    }
    const children = detail?.map((item) => {
        return <p>
            <Space direction={"horizontal"} size={"middle"}>
                <span>{getStatus(item.status)}</span>
                <span> {item.createTime}</span>
            </Space>
        </p>
    });
    return <>
        <p>平台单号: {props.data?.sn}</p>
        <p>物流单号: {props.data?.orderSn}</p>
        <p>收货人: {props.data?.name}</p>
        <p>联系电话: {props.data?.phone}</p>
        <p>收货地址: {props.data?.address}</p>
        <p>物品:{props.data?.name}</p>
        <p>价格: {props.data?.price}</p>
        <p>等级: <span style={{
            border: '1px solid #F00',
            padding: '0px 4px',
            borderRadius: 4,
            color: '#F00',
        }}>{props.data?.level}</span></p>
        <p><Image src={Config.fileUrl(props.data?.logo)} width={80} height={80}/></p>
        <p>创建时间: {props.data?.createTime}</p>
        <p>物流状态:</p>
        {children}
    </>
}

const Order = () => {
    const [dataSource, setDataSource] = useState<DataType[]>()
    const [total, setTotal] = useState(0)

    const [filter, setFilter] = useState({page: 0, size: 10, snt: 0} as Filter)
    const [dialog, dialogHolder] = Modal.useModal()
    const dialogSnRef = useRef<InputRef>(null)
    const [selected, setSelected] = useState<DataType>()

    const [formRef] = useForm()
    const handleChange = (item: DataType) => {
        let instance = dialog.confirm({
            title: '修改订单',
            closable: true,
            icon: null,
            width: 'auto',
            centered: true,
            maskClosable: true,
            content: <div style={{marginBottom: 16, padding: 16}}>
                <Form form={formRef}>
                    <Form.Item style={{display: 'none'}} key={'id'} name={'id'}>
                        <Input/>
                    </Form.Item>
                    <Form.Item style={{display: 'none'}} key={'sn'} name={'sn'}>
                        <Input/>
                    </Form.Item>

                    <Row gutter={8} align={'middle'} justify={"center"}>
                        <Col>物流单号:</Col>
                        <Col>
                            <Form.Item name={'orderSn'} key={'orderSn'}
                                       style={{height: 'auto', margin: 0}}>
                                <Input ref={dialogSnRef} style={{width: 300}} allowClear/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={8} align={'middle'} justify={"center"} style={{marginTop: 8}}>
                        <Col>订单状态:</Col>
                        <Col>
                            <Form.Item name={'status'} key={'status'}
                                       style={{height: 'auto', margin: 0}}>
                                <Select style={{width: 300}}>
                                    {/* 物流状态 0:待付款, 1: 备货, 2: 发货, 3: 运输, 4: 送达: 7: 已收货*/}
                                    <Select.Option value={0} key={'0'}>待付款</Select.Option>
                                    <Select.Option value={1} key={'1'}>备货中</Select.Option>
                                    <Select.Option value={2} key={'2'}>已发货</Select.Option>
                                    <Select.Option value={3} key={'3'}>运输中</Select.Option>
                                    <Select.Option value={4} key={'4'}>已送达</Select.Option>
                                    <Select.Option value={7} key={'7'}>已收货</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>,
            footer:
                <Flex gap={'middle'} justify={'end'}>
                    <Button onClick={() => instance.destroy()} type={'default'}>取消</Button>
                    <Button onClick={() => {
                        instance.destroy()
                        if (item.status == 0) {
                            message.warning('请先修改支付状态')
                        } else {
                            updateOrder()
                        }
                    }} type={'primary'}>确定</Button>
                </Flex>
        })
        formRef.setFieldsValue({
            id: item.id,
            sn: item.sn,
            orderSn: item.orderSn,
            status: item.status,
        })
    }
    const columns = [
        {
            title: '单号',
            dataIndex: 'sn',
            key: 'sn',
            render: (_: any, item: DataType) => <div>
                <Row gutter={8}>
                    <Col>平台单号:</Col>
                    <Col>{item.sn}</Col>
                </Row>
                <Row gutter={8}>
                    <Col>物流单号:</Col>
                    <Col>{item.orderSn}</Col>
                </Row>
            </div>,
        },
        {
            title: '物品',
            dataIndex: 'ware',
            key: 'ware',
            render: (_: any, item: DataType) => <div>
                <Row gutter={16}>
                    <Col>
                        <Image src={Config.fileUrl(item.logo)} width={80} height={80}/>
                    </Col>
                    <Col>
                        <Row><Col><span style={{fontSize: 20, fontWeight: 500}}>{item.name}</span></Col></Row>
                        <Row><Col><span style={{
                            border: '1px solid #F00',
                            padding: '0px 4px 0px 4px',
                            borderRadius: 4,
                            color: '#F00',
                        }}>{item.level}</span></Col></Row>
                        <Row> <Col><span>{item.price}</span></Col></Row>
                    </Col>
                </Row>
            </div>
        },
        {
            title: '收货信息',
            dataIndex: 'address',
            key: 'address',
            render: (_: any, item: DataType) => <div>
                <Row gutter={8}><Col>收货人:</Col><Col>{item.name}</Col></Row>
                <Row gutter={8}><Col>联系号码:</Col><Col>{item.phone}</Col></Row>
                <Row gutter={8}><Col>收货地址:</Col><Col>
                    <div>{item.address}</div>
                </Col></Row>
            </div>,
        },
        {
            title: '提货时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (_: any, item: DataType) => {
                //         物流状态 1: 备货, 2: 发货, 3: 运输, 4: 送达: 7: 已收货
                switch (item.status) {
                    case 0:
                        return <div>待付款</div>
                    case 1:
                        return <div>备货中</div>
                    case 2:
                        return <div>已发货</div>
                    case 3:
                        return <div>运输中</div>
                    case 4:
                        return <div>已送达</div>
                    case 7:
                        return <div>已收货</div>
                    default:
                        return <div>未知</div>
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (_: any, item: DataType) => <>
                <Button type={'link'} onClick={() => handleChange(item)}>修改</Button>
                <Button type={'link'} onClick={() => {
                    setSelected(item)
                    setOpen(true)
                }}>查看</Button>
            </>,
        }
    ]
    const updateOrder = () => {
        post('/sys/order/update', {
            params: formRef.getFieldsValue(),
            success: () => {
                loadData(filter)
            }
        })
    }

    const nameRef = useRef<InputRef>(null)
    const phoneRef = useRef<InputRef>(null)
    const snRef = useRef<InputRef>(null)
    const [open, setOpen] = useState(false)

    const loadData = (params: Filter) => {
        post('/sys/order/list', {
            params: params,
            success: (data) => {
                setDataSource(data.data)
                setTotal(data.total)
            }
        })
    }

    const handleSearch = () => {
        const params = {
            ...filter,
            name: nameRef.current?.input?.value,
            phone: phoneRef.current?.input?.value,
            sn: snRef.current?.input?.value
        }
        setFilter(params)
        loadData(params)
    }

    useEffect(() => {
        loadData(filter)
    }, []);

    return <div>
        <Flex wrap={'wrap'} gap={"middle"} flex={"wrap"}>
            <Input addonBefore={'收货人:'} style={{width: 300}} size={"middle"} allowClear ref={nameRef}/>
            <Input addonBefore={'联系电话:'} style={{width: 300}} size={"middle"} allowClear ref={phoneRef}/>
            <Input addonBefore={
                <Select options={[{label: '全部单号:', value: 0}, {label: '平台单号:', value: 1}, {
                    label: '物流单号:', value: 2
                }]}
                        defaultValue={0} onSelect={(value) => {
                    setFilter({...filter, snt: value})
                }}/>
            } style={{width: 300}} size={"middle"} allowClear ref={snRef}/>
            <span style={{minWidth: 300, width: "auto"}}
                  className={'ant-input-group-wrapper ant-input-group-wrapper-outlined css-kghr11'}>
                <span className={'ant-input-wrapper ant-input-group css-kghr11'}>
                    <span className={'ant-input-group-addon'}>状态:</span>
                    <Select mode={"multiple"} variant='borderless' allowClear
                            className={'ant-input css-dev-only-do-not-override-kghr11 ant-input-outlined'}
                            size={"middle"} onChange={(value) => {
                        setFilter({...filter, status: value})
                    }}>

                        {/* 物流状态 1: 备货, 2: 发货, 3: 运输, 4: 送达: 7: 已收货*/}
                        <Select.Option value={1} key={'1'}>备货中</Select.Option>
                        <Select.Option value={2} key={'2'}>已发货</Select.Option>
                        <Select.Option value={3} key={'3'}>运输中</Select.Option>
                        <Select.Option value={4} key={'4'}>已送达</Select.Option>
                        <Select.Option value={7} key={'7'}>已收货</Select.Option>
                    </Select>
                </span>
            </span>

            <span
                className={'ant-input-group-wrapper ant-input-group-wrapper-outlined css-kghr11'}
                style={{width: 'auto'}}>
                <span className={'ant-input-wrapper ant-input-group css-kghr11'}>
                    <span className={'ant-input-group-addon'}>更新时间:</span>
                  <span
                      className={'ant-select ant-select-borderless ant-input css-dev-only-do-not-override-kghr11 ant-input-outlined css-kghr11 ant-select-single ant-select-show-arrow'}>
                        <DatePicker.RangePicker variant='borderless' placeholder={['开始日期', '结束日期']}
                                                allowClear allowEmpty={[true, true]}
                                                size={"middle"}
                                                onChange={(value, dateString) => {
                                                    setFilter({...filter, start: dateString[0], end: dateString[1]})
                                                }}>
                    </DatePicker.RangePicker>
                  </span>
                </span>
            </span>

            <span style={{width: 300}}
                  className={'ant-input-group-wrapper ant-input-group-wrapper-outlined css-kghr11'}>
                <span className={'ant-input-wrapper ant-input-group css-kghr11'}>
                    <span className={'ant-input-group-addon'}>排序:</span>
                      <Select options={[{label: '时间升序', value: 1},
                          {label: '时间降序', value: 2},
                          {label: '状态升序', value: 3},
                          {label: '状态降序', value: 4}]}
                              size={"middle"} variant='borderless'
                              className={'ant-input css-dev-only-do-not-override-kghr11 ant-input-outlined'}
                              onChange={(value) => {
                                  setFilter({...filter, sort: value})
                              }}/>
                </span>
            </span>
            <Button type={"primary"} onClick={handleSearch}>搜索</Button>

        </Flex>
        <Table dataSource={dataSource} columns={columns} style={{marginTop: 16}} bordered
               pagination={{
                   pageSize: filter.size,
                   defaultCurrent: 1,
                   total: total,
                   showTotal: (total, range) => `共 ${total} 条`,
                   onChange: (page, pageSize) => {
                       setFilter({...filter, page: page - 1, size: pageSize})
                   }
               }}/>
        {dialogHolder}

        <Drawer
            title="订单详情"
            placement={'right'}
            onClose={() => {
                setOpen(false)
            }}
            open={open}
        >
            <Detail data={selected}/>
        </Drawer>
    </div>
}

export default Order;