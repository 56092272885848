import {Button, DatePicker, Flex, Input, Table, type TableProps} from "antd";
import React, {useEffect, useRef, useState} from "react";
import post from "../Request";

interface Bill {
    id: number,
    name: string,
    coin: number,
    time: number
}

interface Page {
    total: number,
    list: Bill[]
}

const columns: TableProps<Bill>['columns'] = [
    {
        key: 'name',
        dataIndex: 'name',
        title: '用户名',
    },
    {
        key: 'coin',
        dataIndex: 'coin',
        title: '金额',
        render: (_, {coin}) => <>{coin > 0 ? <span style={{color: 'green'}}>+{coin}</span> :
            <span style={{color: 'red'}}>{coin}</span>}</>
    },
    {
        dataIndex: 'time',
        key: 'time',
        title: '时间',
        render: (_, {time}) => <>{new Date(time).toLocaleString('zh-CN', {timeZone: 'Asia/Shanghai'})}</>
    }
]


export const Bill = () => {
    const [data, setData] = useState<Page>()
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(10)
    const [name, setName] = useState()
    const [date, setDate] = useState<[string, string]>()
    const nameRef = useRef(null);

    const loadData = (p: number, s: number) => {
        post("/sys/payment/bill", {
            params: {
                page: p - 1,
                size: s,
                start: date?.[0],
                end: date?.[1],
                name: name
            },
            success: (data) => {
                setPage(p)
                setSize(s)
                setData(data)
            }
        })
    }
    useEffect(() => {
        loadData(page, size)
    }, []);

    return <>
        <Flex justify={"start"} align={"center"} style={{width: '100%'}} wrap gap={'middle'}>
            <Input addonBefore={'用户名'} style={{width: '300px'}} allowClear onChange={(e) => {
                // @ts-ignore
                setName(e.target.value)
            }}/>

            <DatePicker.RangePicker
                format="YYYY-MM-DD"
                onChange={(_value, dateString) => {
                    setDate(dateString)
                }}
            />
            <Button type={"primary"} onClick={() => {
                loadData(1, size);
            }}>查询</Button>
        </Flex>
        <h2/>
        <Table columns={columns} dataSource={data?.list}
               pagination={{
                   pageSize: size,
                   current: page,
                   total: data?.total ?? 1,
                   onChange: (p, s) => {
                       loadData(p, s)
                   }
               }}/>
    </>
}