import React, {useEffect, useState} from 'react';
import {Button, TableProps} from 'antd';
import {Image, Table, Tag} from 'antd';
import post from "../../Request";
import {Link, useLocation, useNavigate} from "react-router-dom";

interface DataType {
    id: number,
    content: string,
    imgs: string[] | undefined,
    status: string,
    remark: string | undefined,
    crate_time: number,
    update_time: number,
    name: string
}


export default function Feedback() {
    let [page, setPage] = useState(1)
    const [size, setSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [data, setData] = useState<DataType[] | undefined>()
    let navigate = useNavigate()
    let params = useLocation()

    const columns: TableProps<DataType>['columns'] = [
        {
            title: '用户',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: '内容',
            dataIndex: 'content',
            key: 'content',
            render: (content) => <a>{content}</a>,
        },
        {
            title: '附件',
            dataIndex: 'img',
            key: 'img',
            render: (_, {imgs}) => (
                <Image.PreviewGroup>
                    {imgs?.filter((img) => img != '')
                        ?.map((img) => (
                            <Image width={80} height={80} src={'/' + img} onClick={e => e.stopPropagation()}/>
                        ))}
                </Image.PreviewGroup>
            )
        },
        {
            title: '备注',
            key: 'remark',
            dataIndex: 'remark',
            render: (_, item) => (
                <div>{item.remark}</div>
            ),
        },
        {
            title: '状态',
            key: 'status',
            render: (_, {status}) => <>
                {status == 'WAIT' && <Tag color={"red"}>待处理</Tag>}
                {status == 'DELAY' && <Tag color={"gray"}>延后</Tag>}
                {status != 'DELAY' && status != 'WAIT' && <Tag color={"green"}>完成</Tag>}
            </>
            ,
        },
        {
            title: '创建时间',
            dataIndex: 'crate_time',
            key: 'crate_time'
        },
        {
            key: 'action',
            title: '操作',
            render: (_, item) => <Button type={"link"} onClick={() => {
                navigate('/main/feedback/detail/', {state: {id: item.id}})
            }}>查看</Button>
        }
    ];

    useEffect(() => {
        loadData(1, size)
    }, [params.state]);

    const loadData = (page, size) => {
        post('/sys/feedback/list', {
            params: {
                page: page - 1,
                size: size,
                status: params.state?.status == 'ALL' ? undefined : params.state?.status
            },
            success: (data) => {
                let list = data.list.map((item: any) => {
                    return {
                        id: item.id,
                        content: item.content,
                        name: item.name,
                        imgs: item.imgs?.split(','),
                        status: item.status,
                        remark: item.remark,
                        crate_time: new Date(item.crate_time).toLocaleString('zh-CN', {timeZone: 'Asia/Shanghai'}),
                        update_time: new Date(item.update_time).toLocaleString('zh-CN', {timeZone: 'Asia/Shanghai'}),
                    }
                })
                setTotal(data.total)
                setData(list);
            }
        })
    }
    return (
        <Table columns={columns}
               dataSource={data}
               pagination={{
                   pageSize: size,
                   current: page,
                   total: total,
                   onChange: (p, s) => {
                       setPage(p)
                       setSize(s)
                       loadData(p, s)
                   }
               }}/>
    )
}