import {useLocation, useNavigate} from "react-router-dom";
import {Button, Divider, Image, Input, notification, Select, Space} from "antd";
import {useEffect, useState} from "react";
import post from "../../Request";

interface FeedbackDetail {
    id: number,
    content: string,
    imgs: string | undefined,
    status: string,
    remark: string | undefined,
    crate_time: number,
    update_time: number,
    name: string
}


export const Detail = () => {
    const params = useLocation()
    const [data, setData] = useState<FeedbackDetail | undefined>()
    const [remark, setRemark] = useState(data?.remark)
    const [status, setStatus] = useState(data?.status)
    useEffect(() => {
        post("/sys/feedback/detail/" + params.state.id, {
            success: (data) => {
                setData(data)
                setRemark(data?.remark)
                setStatus(data?.status)
            }
        })
    }, []);


    const submit = () => {
        if (status == undefined || status.length == 0) {
            notification.error({
                message: 'Error',
                description: '请选择状态',
            })
            return
        }
        post("/sys/feedback/update", {
            params: {
                id: Number.parseInt(params.state.id, 10),
                remark: remark,
                status: status
            },
            success: () => {

                notification.success({
                    message: 'Success',
                    description: '已更新',
                    onClick: () => {
                        navigate('/main/feedback/list')
                    },
                })
            }
        })
    }

    const navigate = useNavigate();
    // @ts-ignore
    return (
        <div style={{height: '100%'}}>
            <div style={{height: '30%'}}>
                {data?.content}
            </div>
            <Space size={20} direction={"horizontal"}><h4>状态:</h4>
                <Select value={status}
                        style={{minWidth: '120px'}}
                        placeholder={'请选择'}
                        onChange={(value) => {
                            setStatus(value)
                        }}>
                    <Select.Option value="WAIT">待处理</Select.Option>
                    <Select.Option value="DELAY">延后</Select.Option>
                    <Select.Option value="DONE">完成</Select.Option>
                </Select>
            </Space>
            <h4>附件:</h4>
            <Image.PreviewGroup>
                {
                    data?.imgs
                        ?.split(',')
                        ?.filter((img) => img != '')
                        ?.map((img) => <Image src={'/' + img} width={200} height={200}></Image>)
                }
            </Image.PreviewGroup>
            <Divider/>
            <h4>备注</h4>
            <Space size={32} direction={"vertical"} style={{width: '100%'}}>
                <Input.TextArea maxLength={200} defaultValue={remark} showCount={true} rows={6} onChange={(e) => {
                    setRemark(e.target.value)
                }}/>
                <div style={{textAlign: "center", alignContent: 'center'}}>
                    <Space size={48} direction={"horizontal"}>
                        <Button onClick={() => {
                            navigate('/main/feedback/list')
                        }}>确定</Button>
                        <Button type={"primary"} onClick={() => submit()}>提交</Button>
                    </Space>
                </div>
            </Space>
        </div>
    )
}