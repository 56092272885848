import {
    Button,
    Flex,
    Form,
    Image,
    Input,
    InputNumber, message,
    notification,
    Popconfirm,
    Select, Space,
    Spin,
    Switch,
    Table,
    Typography,
    Upload, UploadProps
} from "antd";
import React, {Component, useEffect, useState} from "react";
import './../index.css'
import TextArea from "antd/es/input/TextArea";
import post from "../Request";
import Config from "../Config";
import cookie from "react-cookies";
import {Link} from "react-router-dom";

interface WareItem {
    id: number,
    name: string,
    price: number,
    sell: number,
    logo: string,
    level: string,
    level_id: number,
    remark: string,
    state: number,
}


interface EditableCellProps {
    title: React.ReactNode;
    children: React.ReactNode;
    dataIndex: keyof WareItem;
    record: WareItem;
    level: Level[];
    editing: boolean;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;


interface LogoProps {
    defaultValue?: string
    onChange: (value: string) => void
}

class Logo extends Component<LogoProps> {
    value: string | undefined

    constructor(props: LogoProps) {
        super(props);
        this.value = this.props.defaultValue
    }


    render() {
        let cookies = cookie.load('account')
        if (cookies === undefined) {
            cookies = {account: '', pass: ''}
        }
        return <Flex wrap={'wrap'} align={'center'}>
            <Image width={80} height={80} src={Config.fileUrl(this.value)}/>
            <Upload maxCount={1}
                    showUploadList={false}
                    type={'select'}
                    customRequest={(opt) => {
                        const data = new FormData()
                        data.append('file', opt.file)
                        fetch('/sys/upload', {
                            method: 'POST',
                            body: data
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                if (res.code === 200) {
                                    this.value = res.data
                                    this.props.onChange(this.value!!)
                                } else {
                                    notification.error({
                                        message: '上传失败',
                                        description: res.msg
                                    })
                                }
                            })
                            .catch((e) => {
                                notification.error({
                                    message: '上传失败',
                                    description: e.message
                                })
                            })
                        return false
                    }}>
                <Button type={'link'} value={''}>选择</Button>
            </Upload>
        </Flex>
    }

}


class Enable extends Component {
    defaultChecked: boolean = false
    onChange: (value: boolean) => void = (value) => {
    }

    constructor(props: { defaultChecked: any, onChange: (value: boolean) => void }) {
        super(props);
        this.defaultChecked = props.defaultChecked === 0
        this.onChange = props.onChange
    }

    render() {
        return <Switch defaultChecked={this.defaultChecked} onChange={this.onChange}/>
    }
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                       title,
                                                       children,
                                                       dataIndex,
                                                       record,
                                                       level,
                                                       editing,
                                                       ...restProps
                                                   }) => {

    let childNode = children;
    if (editing) {
        if (dataIndex === 'logo') {
            const onChange = (value: string) => {
                return value
            }
            childNode = (
                <Form.Item name={dataIndex} style={{margin: 0}} getValueFromEvent={onChange}
                           rules={[{required: true, message: '不能为空'}]}>
                    <Logo defaultValue={record.logo} onChange={onChange}/>
                </Form.Item>
            )
        } else if (dataIndex === 'name') {
            childNode = <>
                <Form.Item name={dataIndex} style={{margin: 0}}
                           rules={[{required: true, message: '不能为空'}]}>
                    <Input/>
                </Form.Item>
            </>
        } else if (dataIndex === 'price' || dataIndex == 'sell') {
            childNode = <Form.Item name={dataIndex} style={{margin: 0}}
                                   rules={[{required: true, message: '不能为空'}, {
                                       type: 'number',
                                       min: 100,
                                       message: '>100'
                                   }]}>
                <InputNumber step={100} min={100}/>
            </Form.Item>
        } else if (dataIndex === 'level_id') {
            let opts = level.map((item) => {
                return {"value": item.id, "label": item.name}
            })
            childNode = <Form.Item name={dataIndex} style={{margin: 0}}
                                   initialValue={record.level_id}
                                   valuePropName={'value'}
                                   rules={[{required: true, message: '不能为空'}]}>
                <Select options={opts}/>
            </Form.Item>
        } else if (dataIndex === 'remark') {
            childNode = <Form.Item name={dataIndex} style={{margin: 0}}>
                <TextArea rows={4} maxLength={255} showCount/>
            </Form.Item>
        } else if (dataIndex === 'state') {
            childNode = <Form.Item name={dataIndex} style={{margin: 0}} valuePropName={'defaultChecked'}
                                   getValueFromEvent={(checked: boolean) => checked ? 0 : 1}>
                <Enable/>
            </Form.Item>
        }
    }

    return (
        <td {...restProps}>
            {editing ? (childNode) : (children)}
        </td>
    );
};


interface Level {
    id: number,
    name: string,
}

function Wares() {
    const [editingKey, setEditingKey] = useState('')
    const [wareList, setWareList] = useState<WareItem[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [levels, setLevels] = useState<Level[]>([]);
    const [form] = Form.useForm();

    const isEditing = (record: WareItem) => record.id.toString() === editingKey;

    const [loading, setLoading] = useState(false)

    const edit = (record: Partial<WareItem>) => {
        form.setFieldsValue({
            name: '',
            price: 0,
            sell: 0,
            logo: '',
            level: '',
            remark: '',
            enable: false,
            ...record
        });
        setEditingKey(record.id?.toString() ?? '');
    };
    const save = async (key: any) => {
        try {
            const row = (await form.validateFields()) as WareItem;
            const l = levels.find((item) => item.id === row.level_id);
            if (l) row.level = l?.name
            const url = key == 0 ? '/sys/wares/insert' : '/sys/wares/update'
            post(url, {
                params: row,
                success: (data: any) => {
                    loadData(page)
                    notification.success({message: '保存成功'})
                    cancel()
                }
            })
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    }

    const defaultColumns = [
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            editable: true,
            render: (_: any, record: WareItem) => <Image width={80} height={80} src={Config.fileUrl(record.logo)}/>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            editable: true
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            editable: true
        },
        {
            title: 'Sell',
            dataIndex: 'sell',
            key: 'sell',
            editable: true
        },
        {
            title: 'Level',
            dataIndex: 'level_id',
            key: 'level_id',
            editable: true,
            render(_: any, record: WareItem) {
                return <span>{record.level}</span>
            }
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
            editable: true
        },
        {
            title: 'Enable',
            dataIndex: 'state',
            key: 'state',
            editable: true,
            render: (_: any, record: WareItem) => (
                <Switch defaultChecked={record.state === 0} disabled/>
            )
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            render: (_: any, record: WareItem) => {

                const editable = isEditing(record);
                const children = editable ? (
                    <span>
                        <Typography.Link onClick={() => save(record.id)} style={{marginRight: 8}}>
                          Save
                            </Typography.Link>
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                          <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                )
                return <div>
                    {children}
                    <Form.Item name={'id'} style={{margin: 0}}>
                        <Input value={record.id} style={{display: 'none'}}/>
                    </Form.Item>
                </div>
            },
        },
    ];

    const cancel = () => {
        setEditingKey('')
        if (editingKey == '0') {
            console.log('cancel')
            const list = wareList.filter((item) => item.id !== 0)
            setWareList(list)
        }
    }

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: WareItem) => ({
                record: record,
                dataIndex: col.dataIndex,
                title: col.title,
                level: levels,
                editing: isEditing(record),
            })
        }
    })

    const components = {
        body: {
            cell: EditableCell,
        }
    }


    const loadData = (page: number) => {
        setLoading(true)
        post('/sys/wares/list', {
            params: {
                "page": page - 1,
                "size": pageSize
            },
            success: (data: any) => {
                cancel()
                setWareList(data.list)
                setTotal(data.total)
            },
            finally: () => {
                setLoading(false)
            }
        })
    }
    useEffect(() => {
        loadData(page)
        post('/sys/levels', {
            success: (data: Level[]) => {
                setLevels(data)
            },
        })
    }, []);


    const uploadProps: UploadProps = {
        name: 'file',
        maxCount: 1,
        method: 'POST',
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        showUploadList: false,
        action: '/sys/wares/upload',
        onChange(info) {
            console.info(info.file)
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                let resp = info.file.xhr.responseText;
                let json = JSON.parse(resp);
                if (json.code == 200) {
                    message.success(`${info.file.name} file uploaded successfully`);
                    loadData(page)
                } else {
                    notification.error({
                        message: `${info.file.name} file upload failed.`,
                        description: json.msg
                    });
                }
            }
        }
    };
    return <div style={{padding: 10, width: '100%', height: '100%', overflow: 'auto'}}>
        <Form form={form} component={false}>
            <Table
                style={{height: '100%'}}
                components={components}
                bordered
                dataSource={wareList}
                columns={columns as ColumnTypes}
                rowKey={(record) => record.id}
                rowClassName={'editable-row'}
                pagination={{
                    defaultPageSize: 10,
                    pageSize: pageSize,
                    total: total,
                    current: page,
                    showTotal: (total) => `共 ${total} 条`,
                    onChange: (page, size) => {
                        cancel()
                        setPage(page)
                        setPageSize(size)
                        loadData(page)
                    },
                }}
                footer={() => <div style={{textAlign: 'end'}}>
                    <Space align={'center'}>
                        <form action={'/sys/wares/export'}>
                            <Button htmlType={'submit'}>导出</Button>
                        </form>
                        <Upload {...uploadProps}>
                            <Button>批量导入</Button>
                        </Upload>
                        <Button type="primary" onClick={() => {
                            let level = levels.length == 0 ? undefined : levels.at(0)
                            const row = {
                                id: 0,
                                name: '',
                                price: 0,
                                sell: 0,
                                level_id: level?.id ?? 0,
                                level: level?.name ?? '',
                                remark: '',
                                state: 0,
                                logo: '',
                            };
                            setWareList([...wareList, row])
                            edit(row)
                        }} disabled={editingKey.length !== 0}>添加</Button>
                    </Space>
                </div>
                }
            />
        </Form>
        {loading && (<Spin tip="Loading" size="large">
            <div className="content"/>
        </Spin>)}
    </div>;
}

export default Wares;