import {Button, DatePicker, Flex, Form, FormInstance, Input, Select, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import post from "../Request";

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    dataIndex: keyof DataType;
    record: DataType;
    handleSave: (record: DataType) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
                                                                                title,
                                                                                editable,
                                                                                children,
                                                                                dataIndex,
                                                                                record,
                                                                                handleSave,
                                                                                ...restProps
                                                                            }) => {

    const [editing, setEditing] = useState(false)
    const toggleEdit = () => {
        setEditing(!editing)
    };

    const save = (value) => {
        toggleEdit();
        handleSave({...record, status: value});
    };

    let childNode = children;

    if (editable) {
        if (editing) {
            // refund	退款 expired	订单过期 wait	待支付 processing	支付中 success	成功 failure	失败 cancel	撤销
            return <td {...restProps}>
                <Select style={{minWidth: 100}} defaultValue={record.status} onChange={save}>
                    <Select.Option value={'REFUND'} key={'REFUND'}>退款</Select.Option>
                    <Select.Option value={'EXPIRED'} key={'EXPIRED'}>订单过期</Select.Option>
                    <Select.Option value={'WAIT'} key={'WAIT'}>待支付</Select.Option>
                    <Select.Option value={'PROCESSING'} key={'PROCESSING'}>支付中</Select.Option>
                    <Select.Option value={'SUCCESS'} key={'SUCCESS'}>成功</Select.Option>
                    <Select.Option value={'FAILURE'} key={'FAILURE'}>失败</Select.Option>
                    <Select.Option value={'CANCEL'} key={'CANCEL'}>撤销</Select.Option>
                </Select>
            </td>
        }
        return <td className={'editable-cell-value-wrap'} {...restProps} onClick={toggleEdit}>
            {childNode}
        </td>
    }

    return <td {...restProps}>{childNode}</td>;
};


const defaultColumns: (ColumnTypes[number] & { editable?: boolean, dataIndex: string })[] = [
    {
        title: '单号',
        dataIndex: 'sn',
        key: 'sn',
        render: (_, item) => <>
            <div>充值单号: {item.orderId}</div>
            <div>平台单号: {item.platId}</div>
        </>
    },
    {
        title: '用户',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '平台',
        key: 'plat',
        dataIndex: 'plat',
    },
    {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: '金币',
        dataIndex: 'coin',
        key: 'coin',
    },
    {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        render: (_, item) => <>{item.type === 1 ? '充值' : '运费'}</>
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        editable: false,
        render: (_, item) => <>{getStatus(item.status)}</>
    },
    {
        title: '时间',
        dataIndex: 'time',
        key: 'time',
        render: (_, item) => <>{new Date(item.time).toLocaleString('zh-CN', {timeZone: 'Asia/Shanghai'})}</>
    }
]

const getStatus = (status: string) => {
    // refund	退款 expired	订单过期 wait	待支付 processing	支付中 success	成功 failure	失败 cancel	撤销
    switch (status.toLowerCase()) {
        case 'refund':
            return '退款'
        case 'expired':
            return '订单过期'
        case 'wait':
            return '待支付'
        case 'processing':
            return '支付中'
        case 'success':
            return '成功'
        case 'failure':
            return '失败'
        case 'cancel':
            return '撤销'
        default:
            return '未知'
    }
}

interface DataType {
    id: number,
    name: string,
    orderId: string,
    platId: string | undefined,
    plat: string,
    amount: number,
    coin: number | undefined,
    type: number,
    time: number,
    status: string
}

interface Response {
    list: DataType[] | undefined,
    count: number,
    total: string,
    month: string,
    today: string,
    amount: string
}

export const Recharge = () => {
    const [formRef] = Form.useForm()
    const [data, setData] = useState<Response>()
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(10)
    const handleSave = (row: DataType) => {
        post('/sys/payment/update', {
            params: {sn: row.orderId, status: row.status},
            success: () => {
                loadData(page, size)
            }
        })
    }
    const columns = defaultColumns.map((col) => {
        if (col.editable) {
            return {
                ...col,
                onCell: (record: DataType) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave
                })
            }
        }
        return col;
    })
    const loadData = (p, s) => {
        const params = {
            ...formRef.getFieldsValue(),
            page: p - 1,
            size: s,
        }
        if (params.type == '') {
            params.type = undefined
        }
        const time = formRef.getFieldValue('time')
        if (time) {
            params.start = time[0].format('YYYY-MM-DD')
            params.end = time[1].format('YYYY-MM-DD')
        }
        post('/sys/payment/list', {
            params: params,
            success: (data) => {
                setData(data)
                setPage(p)
                setSize(s)
            }
        })
    }

    useEffect(() => {
        loadData(page, size)
    }, []);
    return <>
        <Form form={formRef} style={{margin: '0px'}} component={false}>
            <Flex gap={"middle"} wrap align={"center"}>
                <Form.Item name={'name'} style={{margin: '0px'}}>
                    <Input addonBefore={'用户名'} style={{width: '300px'}} allowClear/>
                </Form.Item>
                <Form.Item name={'sn'} style={{margin: '0px'}}>
                    <Input allowClear addonBefore={'订单号'} style={{width: '300px'}}/>
                </Form.Item>
                <span
                    className={'ant-input-group-wrapper ant-input-group-wrapper-outlined css-dev-only-do-not-override-1r287do'}
                    style={{width: '300px'}}>
                        <span className={'ant-input-wrapper ant-input-group css-dev-only-do-not-override-1r287do'}>
                            <span className={'ant-input-group-addon'}>平台</span>
                <Form.Item name={'platform'} style={{margin: 0}}>
                            <Select defaultValue={''} value={formRef.getFieldValue('platform') ?? ''}
                                    className={'ant-input css-dev-only-do-not-override-1r287do ant-input-outlined'}
                                    style={{padding: '0'}}
                                    options={[
                                        {label: '全部', value: ''},
                                        {label: 'ClickPay', value: 'ClickPay'},
                                        {label: 'ToolPay', value: 'ToolPay'},
                                        {label: 'PgPay', value: 'PgPay'}]}
                            >
                            </Select>
                </Form.Item>

                        </span>
                    </span>

                <span
                    className={'ant-input-group-wrapper ant-input-group-wrapper-outlined css-dev-only-do-not-override-1r287do'}
                    style={{width: '300px'}}>
                        <span className={'ant-input-wrapper ant-input-group css-dev-only-do-not-override-1r287do'}>
                            <span className={'ant-input-group-addon'}>类型</span>
                <Form.Item name={'type'} style={{margin: 0}}>
                            <Select defaultValue={''} style={{padding: '0px'}}
                                    value={formRef.getFieldValue('type') ?? ''}
                                    className={'ant-input css-dev-only-do-not-override-1r287do ant-input-outlined'}
                                    options={[
                                        {label: '全部', value: ''},
                                        {label: '充值', value: 1},
                                        {label: '运费', value: 2}]}
                            >
                            </Select>
                </Form.Item>

                        </span>
                    </span>

                <span
                    className={'ant-input-group-wrapper ant-input-group-wrapper-outlined css-dev-only-do-not-override-1r287do'}
                    style={{width: '300px'}}>
                        <span className={'ant-input-wrapper ant-input-group css-dev-only-do-not-override-1r287do'}>
                            <span className={'ant-input-group-addon'}>时间</span>
                <Form.Item style={{margin: 0}} name={'time'}>
                            <DatePicker.RangePicker
                                className={'css-dev-only-do-not-override-1r287do ant-input-outlined'}
                                style={{borderRadius: '0 6px 6px 0'}}>
                            </DatePicker.RangePicker>
                </Form.Item>
                        </span>
                    </span>
                <Form.Item style={{margin: 0}}>
                    <Button onClick={() => {
                        formRef.resetFields()
                    }}>重置</Button></Form.Item>
                <Form.Item style={{margin: 0}}>
                    <Button type={"primary"} onClick={() => loadData(1, size)}>查询</Button>
                </Form.Item>
            </Flex>
        </Form>
        <p/>
        <Table components={{
            body: {
                row: EditableRow,
                cell: EditableCell
            }
        }} columns={columns as ColumnTypes} dataSource={data?.list} pagination={{
            pageSize: size,
            current: page,
            total: data?.count,
            onChange: (p, s) => {
                loadData(p, s)
            }
        }} bordered
               footer={() => <Space direction={"horizontal"} size={"large"}>
                   <span>总计:{data?.total ?? 0}</span>
                   <span>本月:{data?.month}</span>
                   <span>今日:{data?.today}</span>
                   <span>当前:{data?.amount}</span>
               </Space>}/>
    </>
}